import React, {useState} from "react";
import queryString from "query-string";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "./style.styl";



export default function CategorySection(props){
    let {categoryList, selectedDict, onSelect, ecommerceStoreId, isMobile, apiPath} = props;

    return (
        <div className="container u-flexColumn product-gallery-category">
            {
                categoryList.map((item, i) => {
                    if (!item) {
                        return (
                            <div className="item" style={{width: 90, height: 22}}></div>
                        );
                    }

                    const itemIdValue = item.ecommerce_store_category_id;
                    const selectedClass = itemIdValue && selectedDict[itemIdValue] ? " selected" : "";
                    return (
                        <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={itemIdValue && selectedDict[itemIdValue]}
                                    onChange={() => onSelect(itemIdValue)}
                                    className="u-marginLeftNegative10"
                                    color="secondary"
                                  />
                                }

                                label={item.name}
                              />
                    );
                    return (
                        <div key={i} className={"item " + selectedClass}
                             onClick={() => onSelect(itemIdValue)}>
                            {item.name}
                        </div>
                    );
                })
            }

        </div>
    );
}
