import React, {useState, useEffect, useMemo} from "react";
import Button from "../../../components/Button";
import IconButton from "@material-ui/core/IconButton";
import Tune from "@material-ui/icons/Tune";
import Close from "@material-ui/icons/Close";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MuiSelect from "commons-ui/core/MuiSelect";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {KeyboardDatePicker} from "@material-ui/pickers";
import queryString from "query-string";
import {dataSourceV2} from "commons-ui/core/DataSource";
import Loading from "commons-ui/core/Loading";
import CategorySection from "./CategorySection";
import { useRouter } from "../../../hooks/useRouter";
import "./style.styl";


const initSelected = (selected) => {
    const d = {};
    (selected || "").split(",").forEach(v => {
        d[v] = true;
    });
    return d;
};

export default function FilterWidget(props) {
    const {open, onClose, selected, ecommerceStoreGroupId, onSelectMany, isFullscreen, apiPath} = props;
    const [categoryList, setCategoryList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDict, setSelectedDict] = useState(initSelected(selected));
    const location = useRouter();
    const filters = queryString.parse(location.search);

    const onSelect = (id) => {
        const selected = {...selectedDict};
        if (selected[id]) {
            delete selected[id];
        } else {
            selected[id] = true;
        }
        setSelectedDict(selected);
    };

    const request = () => {
      if (!ecommerceStoreGroupId) {
        return;
      }
      //item_id_name=ecommerce_store_id&item_id=${ecommerceStoreId}
      dataSourceV2(`ecommerce_store_category_list?ecommerce_store_group_id=${ecommerceStoreGroupId}`, {
        url: apiPath,
      }).then((v) => {
        if (!v.message && v.item_list && v.item_list.length) {
          const items = v.item_list
            .filter((v) => {
              return (
                !v.is_hidden &&
                (!v.type || v.type === "primary" || v.type === "preview")
              );
            });
          setCategoryList(items);
        }
        setLoading(false);
      });
    };

    useEffect(() => {
      request();
    }, [ecommerceStoreGroupId]);


    return (
        <Dialog
            fullScreen={isFullscreen}
            maxWidth="xs"
            fullWidth
            className=""
            open={open}
            onClose={onClose}

            >
            <div className="u-flexCenter u-justifyContentSpaceBetween u-height65">
                <div className="u-flexCenter">
                    <DialogTitle><span className="u-textColorSecondary u-fontWeightBold">Filters</span></DialogTitle>
                </div>
                <div>
                    {
                        Object.keys(selectedDict).filter(v => !!v).length !== 0 ?
                            <Button color="secondary" type="outlined" className="u-marginRight10" onClick={() => {onSelectMany([]); onClose();}}>
                                Reset
                            </Button>
                            :
                            null
                    }

                    <Tooltip title="Close">
                        <IconButton className="u-marginRight10"
                                    onClick={onClose}>
                            <Close/>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <DialogContent className="">
                {
                    loading ?
                        <Loading className="u-paddingBottom100"/>
                        :
                        <CategorySection {...props} selectedDict={selectedDict} categoryList={categoryList} onSelect={onSelect}/>
                }

            </DialogContent>
            <DialogActions>
                <div className="u-sizeFullWidth u-flexCenter u-justifyContentCenter">
                    <Button color="secondary"
                            size="large"
                            onClick={() => {onSelectMany(Object.keys(selectedDict || {})); onClose();}}>
                        Apply
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
