import React, {useEffect, useState} from "react";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import "./style.styl";


export default function SearchWidget(props) {
    const {value, placeholder, onSearch, color, onClose} = props;
    const [search, setSearch] = useState(value);

    const onChange = (value) => {
        props.onChange ? props.onChange(value) : null;
        setSearch(value);
    };

    useEffect(() => {
        onChange(value);
    }, [value]);


    return (
        <div className={"searchbar-widget u-height48 u-flexCenter u-minWidth240 u-relative u-justifyContentSpaceBetween u-borderRadius4 u-backgroundColorGrayLight u-paddingVertical3 u-paddingRight5 u-paddingLeft10 " + (color === "dark" ? " dark " : "")}>
            <InputBase onChange={(event)=> onChange(event.target.value)}
                   value={search}
                   placeholder={placeholder || "Search..."}
                   className="u-sizeFullWidth"
                   onKeyPress={(e)=> {
                       if (e.key === "Enter") {
                           onSearch(search);
                       }
                   }}/>
            <IconButton className="u-padding3 u-opacity80" onClick={() => onSearch(search)}>
               <Search className="u-textColorBlack"/>
            </IconButton>
            {
                onClose ?
                    <div className="u-absolute u-rightNegative36">
                        <IconButton className="u-padding3 u-opacity80" onClick={onClose}>
                           <Close/>
                        </IconButton>
                    </div>
                    :
                    null
            }

        </div>
    );
}
