import React, {useState, useMemo} from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "../../../components/Button";
import "./style.styl";



export default function DeliveryFilter(props) {
    const {hasDelivery, hasTakeout, className, onSelect} = props;

    return (
        <React.Fragment>
            <ButtonGroup className={"delivery-filter-custom u-xs-marginBottom0" + (className || "")}>
                <Button color="secondary" isWhite size="large" type={hasDelivery ? undefined : "outlined"} className="u-height50" onClick={() => onSelect("has_delivery", !hasDelivery)}>
                    Delivery
                </Button>
                <Button color="secondary" isWhite size="large" type={hasTakeout ? undefined : "outlined"} className="u-height50" onClick={() => onSelect("has_take_out", !hasTakeout)}>
                    Takeout
                </Button>
            </ButtonGroup>
        </React.Fragment>
    );
}
