import React, {useEffect, useState} from "react";
import {dataSourceV2} from "commons-ui/core/DataSource";
import queryString from "query-string";
import Gallery from "ecommerce-commons-ui/core/ProductGallery/Gallery";
import Item from "./Item";
import {
    onAddFilterTemplate,
    onDeleteFilterManyTemplate,
    onDeleteFilterTemplate,
    onLocationChangeTemplate,
    onResetFacetFiltersTemplate,
    requestProductListTemplate
} from "ecommerce-commons-ui/core/ProductGallery";
import { useStore } from "../../../context/state";
import { useRouter } from "../../../hooks/useRouter";
import "./style.styl";


export default function StoreSection(props) {
    const {filters, category_widget, withDescriptionData, withCategoryBlock, withInfoBlock, withArticleBlock, isBrandPage, returnNull, noImage} = props;
    const {location_widget, apiPath, isMobile, dispatch, mediaPath, ecommerceStoreId, ecommerceStoreGroupId} = useStore();
    const location = useRouter();
    const [itemData, setItemData] = useState({itemList: [], pageTotal: 0, itemTotal: 0, pageSize: 25, searchSynonymList: []});
    const {itemList, pageTotal, itemTotal, pageSize, searchSynonymList} = itemData;
    const [facetData, setFacetData] = useState({facetDataDict: {}, facetDataList: []});
    const {facetDataDict, facetDataList} = facetData;
    const [filterSelectedDict, setFilterSelectedDict] = useState({});
    const [loading, setLoading] = useState(true);

    const modelName = "ecommerce_store";
    const itemId = "ecommerce_store_id";

    const isCategoryPreview = false;

    const createSortQuery = () => {
        if (!location_widget.latitude || !location_widget.longitude) return [];
        const res = [
            {
                  "_geo_distance" : {
                      "search_data.address_coordinates" : {
                          "lat": parseFloat(location_widget.latitude),
                          "lon": parseFloat(location_widget.longitude)
                      },
                      "order" : "asc",
                      "unit" : "km",
                      "mode" : "min",
                      "distance_type" : "arc",
                      "ignore_unmapped": true
                  }
            }
        ];
        return res;
    };

    const getFilterQuery = () => {
        const res = [];
        {/*if (!filters.distance__filter && location_widget.stateLatitude && location_widget.stateLongitude) {
            const lat = parseFloat(location_widget.stateLatitude)
            const lon = parseFloat(location_widget.stateLongitude)
            const geoFilter = {
                "geo_bounding_box": {
                    "search_data.address_coordinates": {
                        "top_left": {
                            "lat": lat + 1,
                            "lon": lon - 3
                        },
                        "bottom_right": {
                            "lat": lat - 1,
                            "lon": lon + 3
                        }
                    }
                }
            }
            res.push(geoFilter)
        }*/}
        if (location_widget.stateSelected) {
            //res.push({ "term":  { "search_data.state": location_widget.stateSelected }})
        }
        if (filters.distance__filter && location_widget.latitude && location_widget.longitude) {
            const lat = parseFloat(location_widget.latitude);
            const lon = parseFloat(location_widget.longitude);
            const geoFilter = {
                "geo_distance": {
                  "distance": filters.distance__filter + "mi",
                  "search_data.address_coordinates": {
                    "lat": lat,
                    "lon": lon
                  }
                }
            };
            res.push(geoFilter);
        }
        if (filters.has_delivery__filter === "true") {
            res.push({ "term":  { "search_data.has_delivery": true }});
        }
        if (filters.has_take_out__filter === "true") {
            res.push({ "term":  { "search_data.has_take_out": true }});
        }
        return res;
    };

    const request = requestProductListTemplate({createSortQuery, setItemData, getFilterQuery, setFacetData, path: "ecommerce_store_view_list", itemId: "ecommerce_store_id", postprocess: "default", apiPath, ecommerceStoreGroupId, loading, setLoading, searchInStoreGroup: true, noFacet: true, hiddenDirect: true});
    const onAddFilter = onAddFilterTemplate({location, dispatch, request, setFilterSelectedDict});
    const onDeleteFilter = onDeleteFilterTemplate({location, dispatch, request, setFilterSelectedDict});
    const onDeleteFilterMany = onDeleteFilterManyTemplate({location, dispatch, request, setFilterSelectedDict});
    const onResetFacetFilters = onResetFacetFiltersTemplate({location, onDeleteFilterMany});

    const onLocationChange = onLocationChangeTemplate({location, loading, request, filterSelectedDict, setFilterSelectedDict});


    useEffect(() => {
        if (returnNull || isCategoryPreview) {
            return;
        }
        const filterSelectedDict = queryString.parse(location.search);
        if (location.search) {
            setFilterSelectedDict(filterSelectedDict);
        }
        request(filterSelectedDict);
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (returnNull || isCategoryPreview) {
            return;
        }
        if (location.search && location_widget.latitude && location_widget.stateLatitude) {
            const filterSelectedDict = queryString.parse(location.search);
            request(filterSelectedDict);
        }
    }, [location_widget.latitude, location_widget.stateSelected]);


    useEffect(() => {
        if (returnNull) {
            return;
        }
        onLocationChange();
    }, [location.search]);


    return (
        <div id="store-result-section">
            <Gallery      {...props}
                          isMobile={isMobile}
                          items={itemList}
                          itemId="ecommerce_store_id"
                          modelName="ecommerce_store"
                          loading={loading}
                          facetDataDict={facetDataDict}
                          facetDataList={facetDataList}
                          filterSelectedDict={filterSelectedDict}
                          itemComponent={(props) => {
                              return (
                                  <div className="item u-xs-padding5">
                                      <Item item={props.item} {...(props.props || {})} loading={loading}/>
                                  </div>
                              );
                          }}
                          onAddFilter={onAddFilter}
                          onDeleteFilter={onDeleteFilter}
                          onResetFacetFilters={onResetFacetFilters}
                          categoryPreviewList={[]}
                          pageTotal={pageTotal || 0}
                          searchSynonymList={searchSynonymList}
                          onItemClick={props.onItemClick}
                          pageSelected={filterSelectedDict.page || 1}
                          onSelectPagination={(page) => onAddFilter("page", page)}/>
        </div>
    );
}
