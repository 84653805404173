import React, {useState, useMemo} from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import "./style.styl";

export const items = [
    {value: "", name: "Any Distance"},
    {value: "1", name: "within 1 mi"},
    {value: "2", name: "within 2 mi"},
    {value: "5", name: "within 5 mi"},
    {value: "10", name: "within 10 mi"},
    {value: "20", name: "within 20 mi"}
];



export default function DistanceFilter(props) {
    const {className, onSelect} = props;
    const [open, setOpen] = useState(null);

    const selected = props.selected || "";
    const selectedItem = useMemo(() => {
        const selectedList = items.filter(v => v.value === selected);
        if (selectedList.length > 0) return selectedList[0];
        return items[0];
    }, [selected]);
    return (
        <React.Fragment>
            <Tooltip title="Distance Filter">
                <Button size="large" variant="outlined" color={"secondary"} className={"u-round " + (className || "")} onClick={(e) => setOpen(e.currentTarget)}>
                    {selectedItem.name}
                </Button>
            </Tooltip>
            <Menu
              anchorEl={open}
              className="location-widget-menu"
              open={Boolean(open)}
              onClose={() => setOpen(null)}
            >
               <List>
                  {
                      items.map((item, i) => {
                          const isSelected = item.value === selected;
                          return (
                              <ListItem key={i} button selected={isSelected} onClick={() => {onSelect(item.value); setOpen(null);}}>
                                <div className="u-flexCenter">
                                    {item.name}
                                </div>
                              </ListItem>
                          );
                      })
                  }
              </List>
            </Menu>
        </React.Fragment>
    );
}
