import React, {useState} from "react";
import queryString from "query-string";
import StoreSection from "./StoreSection";
import SalesSection from "./SalesSection";
import Button from "../../components/Button";
import Menu from "@material-ui/icons/Menu";
import Badge from "@material-ui/core/Badge";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import {dataSourceV2} from "commons-ui/core/DataSource";

import { addParamToQueryString, changeParamsInQueryString, deleteParamFromQueryString } from "ecommerce-commons-ui/core/utils";
import Logo from "../../components/Logo";
import SearchWidget from "../../components/SearchWidget";
import DistanceFilter from "./DistanceFilter";
import DeliveryFilter from "./DeliveryFilter";
import FilterListWidget from "./FilterListWidget";
import FilterWidget from "./FilterWidget";
import { useStore } from "../../context/state";
import { useRouter } from "../../hooks/useRouter";
import "./HomeView.module.scss";

export default function HomeView(props) {
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [emailSub, setEmailSub] = useState("");
    const [nameSub, setNameSub] = useState("");
    const [alertOn, setAlertOn] = useState(false);

    const location = useRouter();
    const {dispatch, isMobile, ecommerceStoreId, apiPath, ecommerceStoreGroupId} = useStore();
    const filters = queryString.parse(location.search);
    const returnNull = !filters.category && !filters.search;
    const unique = filters.unique;
    const onItemClick = (id) => {
        dispatch("/store/" + id + "?" + queryString.stringify(filters));
    };
    
    const sendSubscription = () => {

        let value = {"name": "Caio",
                    "email": "caio@catch22market.com",
                    "email_to": "caio@catch22market.com"
        }

        console.log("sdsd", value)
        setAlertOn(true)
        setTimeout(function () {
            setAlertOn(false)

        }, 5000);
        

        dataSourceV2("customer_message_create", {data: {item: value}, url: apiPath})
        .then(v => {
            props.setSubmitting(false);
            if (v.item) {
                onSuccess();
            }
        });

    }
    
    const categoryCount = (filters.category || "").split(",").filter(v => !!v).length;

    const search = (
        <div className={"u-xs-sizeFullWidth u-flexCenter u-justifyContentCenter " + (isMobile ? "u-marginRight10 u-xs-marginHorizontal0 u-xs-sizeFullWidth u-marginTop12 u-marginBottom15" : "u-sizeFullHeight u-flexCenter")}>
            <SearchWidget   {...props}
                value={filters.search || ""}
                onChange={() => null}
                color="dark"
                placeholder="Explore Near Me"
                onSearch={(value) => dispatch(changeParamsInQueryString([{ key: "search", value: value }, { key: "page" }, { key: "category" }], location))} />
        </div>
    );


    let content = (
        <>
            <SalesSection  {...props}
                type="distance"
                columns={5}
                size={8}
                filters={filters}
                itemId="ecommerce_store_id"
                labelClassName="u-sizeFullWidth u-justifyContentStart u-marginBottom15 u-fontWeightBold"
                onItemClick={(id) => onItemClick(id)}
                //onMore={() => dispatch("/?" + queryString.stringify({...filters, category: "1SeD9gi7hGZKU0JQOAC92Y7MD4o"}))}
                label={<span className="u-textColorSecondary">Near You</span>}
            />
            <SalesSection  {...props}
                type="new"
                columns={5}
                size={8}
                filters={filters}
                itemId="ecommerce_store_id"
                labelClassName="u-sizeFullWidth u-justifyContentStart u-marginBottom15 u-fontWeightBold"
                onItemClick={(id) => onItemClick(id)}
                //onMore={() => dispatch("/?" + queryString.stringify({...filters, category: "1SeD9gi7hGZKU0JQOAC92Y7MD4o"}))}
                label={<span className="u-textColorSecondary">New Restaurants</span>}
            />
            <SalesSection  {...props}
                category="0922c068-ee88-40c3-9b77-45b9aa5ed668"
                columns={5}
                filters={filters}
                size={8}
                itemId="ecommerce_store_id"
                labelClassName="u-sizeFullWidth u-justifyContentStart u-marginBottom15 u-fontWeightBold"
                onItemClick={(id) => onItemClick(id)}
                //onMore={() => dispatch("/?" + queryString.stringify({...filters, category: "1SeD9gi7hGZKU0JQOAC92Y7MD4o"}))}
                label={<span className="u-textColorSecondary">Trending</span>}
            />
        </>
    );

    if (!returnNull) {
        content = (
            <div className="container u-flexColumn" style={{ paddingBottom: 200 }}>
                <span className="u-flexCenter u-paddingBottom20 u-flexWrap">
                    <span className="u-fontSize20 u-fontWeightBold u-textColorSecondary u-marginRight15">
                        Filters Applied:
                    </span>
                    <FilterListWidget itemsDict={filters} onDelete={(key) => dispatch(deleteParamFromQueryString(key, location))}/>
                </span>
                <StoreSection  {...props} filters={filters} onItemClick={(id) => onItemClick(id)} />
            </div>
        );
    }

    const distanceFilter = (
        <DistanceFilter selected={filters.distance__filter || ""}
            className="filter-button u-marginRight15 u-xs-marginRight0 u-xs-sizeFullWidth"
            onSelect={(id) => dispatch(changeParamsInQueryString([{ key: "distance__filter", value: id }], location))} />
    );

    const deliveryFilter = (
        <DeliveryFilter hasDelivery={filters.has_delivery__filter === "true"}
            hasTakeout={filters.has_take_out__filter === "true"}
            className="filter-button u-marginRight15 u-xs-marginRight0"
            onSelect={(key, value) => dispatch(changeParamsInQueryString([{ key: key + "__filter", value: value || undefined }, {key: key === "has_delivery" ? "has_take_out__filter" : "has_delivery__filter", value: undefined}], location))} />
    );

  return (
    <>
      <div className="home-page">
        <div className=" flex flex-col justify-center ">
            <div className="u-relative background-main-wrapper u-marginBottom60">
              <div className="u-hide container u-relative u-flexCenter u-justifyContentCenter u-marginTop60 u-xs-textAlignCenter">
                <h2 className={"u-textColorWhite u-fontWeightBold " + (isMobile ? "u-fontSize40" : "")} style={{fontSize: 52, fontFamily: "'Lobster Two', cursive"}}>
                    Show Love for your Local Favorites
                </h2>
              </div>

              <div className="container u-textColorWhite u-fontSize28 u-marginTop20 u-marginBottom50 u-flexColumn u-justifyContentCenter u-alignItemsCenter u-textAlignCenter">
                  <div style={{maxWidth: "800px"}}>
                      <p className="u-marginBottom15">
                         <span className="u-fontWeightBold" style={{fontSize: "35px",fontFamily: "'Lobster Two', cursive"}}>Support your local businesses! Get fed by people who care.</span>
                      </p>
                      {/* <br/>
                      <p className="u-marginTop5 u-textColorWhite">
                          We're taking a break  to revisit how we can best support the vibrant food scene in Seattle and around the country. 
                      </p>
                      <br/>
                      <p className="u-marginTop5 u-textColorWhite">
                         But don't worry, we'll be back better than ever. In <span className="u-fontWeightBold" style={{fontFamily: "'Lobster Two', cursive"}}>Spring of 2022</span>. 
                      </p> */}
                  </div>
              </div>

              <div className="filter-box u-marginTop30 u-xs-marginTop0 u-marginBottom30">
                  <div className=" u-flexColumn u-alignItemsCenter u-justifyContentCenter u-paddingBottom0 u-paddingTop20">
                      <div className="container u-marginBottom30 u-xs-marginBottom10 u-sizeFullWidth u-flexCenter u-justifyContentCenter u-xs-flexColumn">
                          {search}
                          {/* <div className="u-flexCenter u-marginLeft15 u-xs-marginLeft0 u-justifyContentSpaceBetween u-xs-sizeFullWidth u-xs-marginBottom30">
                              {deliveryFilter}
                              <Badge badgeContent={categoryCount} color="secondary" invisible={!categoryCount}>
                                  <Button color="secondary" isWhite size="large" type="outlined" className="u-height50" onClick={() => setIsFilterOpen(!isFilterOpen)}>
                                      Filters <Menu className="u-marginLeft5"/>
                                  </Button>
                              </Badge>
                          </div> */}
                      </div> 
                      
                     {/* <CategorySection  {...props}
                          hideHeader
                          onSelect={(id) => {
                              let check = false;
                              const categoryList = (filters.category || "").split(",").filter(v => {
                                  if (v === id) check = true;
                                  return v !== id && !!v;
                              });
                              if (!check && id) categoryList.push(id);
                              dispatch(changeParamsInQueryString([{ key: "category", value: categoryList.join(",") }, { key: "page" }, { key: "search" }], location));
                          }}
                          selected={filters.category}
                          categoryList={(category_widget.categoryDict || {}).main || []}
                          label="categories" /> */}
                         
                  </div>
              </div>

        </div>
        <div>
            {
                isFilterOpen ?
                    <FilterWidget {...props}
                                  open={isFilterOpen}
                                  onClose={() => setIsFilterOpen(false)}
                                  ecommerceStoreGroupId={ecommerceStoreGroupId}
                                  hideHeader
                                  isFullscreen={isMobile}
                                  onSelect={(id) => {
                                      let check = false;
                                      const categoryList = (filters.category || "").split(",").filter(v => {
                                          if (v === id) check = true;
                                          return v !== id && !!v;
                                      });
                                      if (!check && id) categoryList.push(id);
                                      dispatch(changeParamsInQueryString([{ key: "category", value: categoryList.join(",") }, { key: "page" }, { key: "search" }], location));
                                  }}
                                  onSelectMany={(categoryList) => {
                                      dispatch(changeParamsInQueryString([{ key: "category", value: categoryList.join(",") }, { key: "page" }, { key: "search" }], location));
                                  }}
                                  selected={filters.category}
                                  apiPath={apiPath}
                                  label="categories" />
                              :
                              null
            }

        </div>
          {content}
            {/* <div className=" u-fontSize20 u-marginHorizontalAuto u-flexCenter u-justifyContentCenter ">
              <div>  Need to contact us or want to be alerted when we're back?</div>          
            </div>

            <div className=" u-marginTop10 u-fontSize20 u-marginHorizontalAuto u-flexCenter u-justifyContentCenter ">
                <div>Leave us an email below, and we'll be in touch!</div>
            </div>


          <div style={{width:"50%"}} className=" u-marginTop50 u-marginHorizontalAuto u-flexCenter u-justifyContentCenter u-xs-flexColumn">
            <div >
                <TextField
                    style={{width:"46%", marginRight:"4%", color:"black", backgroundColor:"blacks"}}
                    label="Email"
                    id="outlined-size-small"
                    onChange={(e) => setEmailSub(e.target.value)}

                    defaultValue=""
                    size="small"
                />
                <TextField style={{width:"50%"}} label="Name" id="outlined-size-small"
                    onChange={(e) => setNameSub(e.target.value)}
                    defaultValue="" size="small" />
            </div>
            <Button style={{backgroundColor:"#c6093e", color:"white", borderRadius:"50px"}} onClick={() => sendSubscription()} className="u-borderDarker u-flexCenter u-marginLeft20 u-marginTop6">Continue</Button>
        </div>  */}
        {alertOn && 
            <Alert style={{position:"fixed", left:"30%", bottom:"50%"}} severity="success">Thank you for your support. We will contact you as soon as we are back!</Alert>
        }
          {returnNull && <div className="u-marginTop80"></div>}
        </div>
      </div>
    </>
  );
}
