import React, {useMemo} from "react";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import Store from "@material-ui/icons/Store";
import { useStore } from "../../../../context/state";
import {addParamToQueryString} from "ecommerce-commons-ui/core/utils";
import {calculateDistance} from "commons-ui/core/Fingerprint";
import "./style.styl";


function Image(props) {
    const {path, noImage, imageHeight, isVertical, isCover, onClick} = props;
    const height = imageHeight || (isVertical ? "260px" : "180px");

    const style = {backgroundImage: `url(${path})`, backgroundSize: isCover ? "cover" : "contain", height};
    if (noImage) {
        style.backgroundSize = "100px";
        style.backgroundRepeat = "no-repeat";
        style.opacity = "0.7";
    }
    return (
        <div className={"u-relative u-inlineBlock u-verticalAlignTop " + (isCover || isVertical ? "u-marginBottom4" : "u-marginVertical4")} onClick={onClick}>
            <div className={"image-widget-item u-relative u-sizeFullWidth u-cursorPointer "}
                 style={style}></div>
        </div>
    );
}


export default function Item(props) {
    const {item, isLoading, loading, itemId, category_widget, noWatermark, onItemClick, isImagesCover, imagesHeight, isImagesVertical, featuresFunc, imageFeaturesFunc, noImage} = props;
    const {location_widget, ecommerceStoreId, ecommerceStoreGroupId, apiPath, mediaPath, dispatch, isMobile} = useStore();
    const itemIdValue = item[itemId || "ecommerce_store_id"];



    const image = ((item.food_images || item.store_images) ? mediaPath + (item.food_images|| item.store_images || "").split(",")[0] : "");

    const categoryFlatDict = (category_widget || {}).categoryFlatDict || {};

    const categoryList = useMemo(() => {
        const categoryList = Object.keys(item.category_id_dict || {}).map((key) => ((categoryFlatDict[key] || {}).ecommerce_store_category || {}));
        return categoryList;
    }, [Object.keys(categoryFlatDict).length]);

    const getDistance = () => {
        const lat1 = location_widget.latitude;
        const lon1 = location_widget.longitude;
        const lat2= item.address_latitude;
        const lon2 = item.address_longitude;
        if (!lat1 || !lon1 || !lat2 || !lon2) return "";
        let distance = calculateDistance(lat1, lon1, lat2, lon2);
        if (distance) {
            distance = "≈" + Math.round(distance).toString() + "mi";
        }
        return distance;
    };

    const distance = useMemo(getDistance, [location_widget.latitude]);

    //const distance = "≈533mi";

    if (isLoading || loading) {
        return (
            <div className="tmallItem">
                <Skeleton className="u-margin10" variant="rect" height={isImagesVertical ? 300 : (isMobile ? 160 : 190)}/>
                <Skeleton className="u-margin10" width={"80%"}/>
                <Skeleton className="u-margin10" width={"60%"}/>
            </div>
        );
    }

    return (
        <div className="tmallItem product-gallery-item u-sizeFullHeight">
            <div className="u-flexColumn u-justifyContentStart u-sizeFullHeight u-relative" link-meta={"/product/" + itemIdValue}>
                {
                    distance &&
                        <div className="image-feature" style={{top: 5}}>
                             {distance}
                        </div>
                }
                <Image noImage={!image}
                       imageHeight={imagesHeight}
                       isVertical={isImagesVertical || item.is_image_vertical === "true"}
                       isCover={true}
                       path={image || "/static/images/no-image.png"}
                       onClick={() => onItemClick(item.slug || itemIdValue)}/>
                   <div className="u-flexCenter u-justifyContentCenter u-sizeFullHeight">
                    <div className="u-marginVertical4 u-paddingHorizontal10 u-textAlignCenter u-textColorBlack  u-fontSize14" onClick={() => onItemClick(item.slug || itemIdValue)}>
                        {item.name}
                    </div>
                    <div className="u-hide u-flex u-flexWrap u-paddingHorizontal10 u-marginTop5">
                        {
                            categoryList.map((category, i) => {
                                if (i > 3) return null;
                                return (
                                    <span className="u-fontSize8 u-marginBottom4 u-borderRadius10 u-marginRight5 u-paddingHorizontal5 u-paddingVertical1" style={{backgroundColor: "rgb(255 217 235)", fontWeight: 100}}>
                                        {(category.name || "").toLowerCase()}
                                    </span>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
