import HomeView from "../views/Home";

export default HomeView;

import { dataSourceV2 } from "commons-ui/core/DataSource";

import { ecommerceStoreGroupId, apiPath } from "../constants";

const requestStores = async () => {
  const type = "new";

  const query = {
    size: 9,
    sort: [],
    _source: ["search_result_data"],
    query: {
      bool: {
        must: [],
        filter: [
          {
            term: {
              "search_data.ecommerce_store_group_id": ecommerceStoreGroupId,
            },
          },
        ],
        must_not: [{ term: { "hidden.id_list": "default" } }],
      },
    },
  };

  if (type == "new") {
    query["sort"].push({ "search_data.created_at": { order: "desc" } });
  }

  return await dataSourceV2(
    `ecommerce_store_view_list?is_search=true&postprocess=default&query=${JSON.stringify(
      query
    )}`,
    { url: apiPath }
  ).then((v) => {
    if (!v.message && v.item_list) {
      const itemList = v.item_list.map((data) => {
        const category_id_dict = data.category_id_dict || {};
        const version = data.version || {};

        return {
          ...version,
          category_id_dict,
          attribute_set_id: data.attribute_set_id,
          ecommerce_store_id: data.ecommerce_store_id,
        };
      });
      return itemList;
    }
    return [];
  });
};

export const getStaticProps = async () => {
  const stores = await requestStores();

  return {
    props: {
      stores,
    },
  };
};
