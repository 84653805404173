import React, {useEffect, useState} from "react";
import ItemGallery from "ecommerce-commons-ui/core/ItemGallery";
import {dataSourceV2} from "commons-ui/core/DataSource";
import { useStore } from "../../../context/state";
import Item from "../StoreSection/Item";
import "./style.styl";


export default function SalesSection(props) {
    const {filters, category, type, size} = props;
    const {location_widget, ecommerceStoreId, ecommerceStoreGroupId, isMobile, apiPath} = useStore();
    const [itemList, setItemList] = useState([]);
    const [loading, setLoading] = useState(true);


    const request = (category) => {

        const query = {
            "size": size || 10,
            "sort": [
            ],
            "_source" : ["search_result_data"],
            "query": {
                "bool": {
                    "must": [],
                    "filter": [
                        { "term":  { "search_data.ecommerce_store_group_id": ecommerceStoreGroupId }}
                    ],
                    "must_not": [
                        { "term":  { "hidden.id_list": "default"}}
                    ]
                }
            }
        };
        {/*if (location_widget.stateLatitude && location_widget.stateLongitude) {
            const lat = parseFloat(location_widget.stateLatitude)
            const lon = parseFloat(location_widget.stateLongitude)
            const geoFilter = {
                "geo_bounding_box": {
                    "search_data.address_coordinates": {
                        "top_left": {
                            "lat": lat + 1,
                            "lon": lon - 3
                        },
                        "bottom_right": {
                            "lat": lat - 1,
                            "lon": lon + 3
                        }
                    }
                }
            }
            query.query.bool.filter.push(geoFilter)
        }*/}
        if (location_widget.stateSelected) {
            //query.query.bool.filter.push({ "term":  { "search_data.state": location_widget.stateSelected }})
        }

        if (type == "distance") {
            if (!location_widget.latitude || !location_widget.longitude) {
                setLoading(false);
                return;
            }
            query["sort"].push(
                {
                      "_geo_distance" : {
                          "search_data.address_coordinates" : {
                              "lat": parseFloat(location_widget.latitude),
                              "lon": parseFloat(location_widget.longitude)
                          },
                          "order" : "asc",
                          "unit" : "km",
                          "mode" : "min",
                          "distance_type" : "arc",
                          "ignore_unmapped": true
                      }
                }
            );
        }
        if (type == "new") {
            query["sort"].push({"search_data.created_at": {"order" : "desc"}});
        }
        if (category) {
            query["query"]["bool"]["filter"].push({ "term":  { "category.id_list": category }});
        }
        if (filters.has_delivery__filter === "true") {
            query.query.bool.filter.push({ "term":  { "search_data.has_delivery": true }});
        }
        if (filters.has_take_out__filter === "true") {
            query.query.bool.filter.push({ "term":  { "search_data.has_take_out": true }});
        }
        if (filters.distance__filter && location_widget.latitude && location_widget.longitude) {
            const lat = parseFloat(location_widget.latitude);
            const lon = parseFloat(location_widget.longitude);
            const geoFilter = {
                "geo_distance": {
                  "distance": filters.distance__filter + "mi",
                  "search_data.address_coordinates": {
                    "lat": lat,
                    "lon": lon
                  }
                }
            };
            query.query.bool.filter.push(geoFilter);
        }
        dataSourceV2(`ecommerce_store_view_list?is_search=true&postprocess=default&query=${JSON.stringify(query)}`, {url: apiPath})
            .then(v=> {

                if (!v.message && v.item_list) {

                    const itemList = v.item_list.map(data => {
                        const category_id_dict = data.category_id_dict || {};
                        const version = data.version || {};

                        return {...version, category_id_dict, attribute_set_id: data.attribute_set_id, ecommerce_store_id: data.ecommerce_store_id};
                    });
                    setItemList(itemList);
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        request(category);
    }, [location_widget.stateSelected, filters.distance__filter, filters.has_take_out__filter, filters.has_delivery__filter]);

    useEffect(() => {
        if (type === "distance") {
            request(category);
        }
    }, [location_widget.latitude]);

    if (!loading && itemList.length === 0) {
        if (type === "new") {
            return (
                <div className="container u-flexCenter u-justifyContentCenter">
                    <span className="u-marginVertical60 u-textColorNormal u-fontSize20">No Restaurants in your Area</span>
                </div>
            );
        }
        return null;
    }

    return (
        <div id="sales-section">
            <div className="container">
                <ItemGallery {...props}
                             loading={loading}
                             itemComponent={(props) => {
                                 return (
                                     <div className="item u-sizeFullHeight u-padding5">
                                         <Item item={props.item} {...(props.props || {})} loading={loading}/>
                                     </div>
                                 );
                             }}
                             loadingItemsCount={size || 10}
                             isMobile={isMobile}
                             items={itemList}/>
            </div>
        </div>
    );
}
