import React, {useState} from "react";
import Chip from "@material-ui/core/Chip";


export default function FilterListWidget(props) {
    let {items, itemsDict, onDelete, returnNull, exclude} = props;
    const [nameInitialDict, setNameInitialDict] = useState({});

    {/*
    useEffect(() => {
        const filtersNameDict = getFromLS("filtersNameDict") || {}
        if (Object.keys(filtersNameDict).length) {
            setNameInitialDict(filtersNameDict)
        }
    }, []);


    useEffect(() => {
        saveToLS("filtersNameDict", nameDict || {})
    });
    */}

    //items = items.filter(v => !v.name)
    if (!items || items.length === 0) {
        items = Object.keys(itemsDict).map(key => ({key, value: itemsDict[key]}));
        items.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
    }
    if (!items.length) {
        return returnNull ? null : <div className="u-height50"></div>;
    }
    //u-noWrap u-xs-overflowScrollX u-overflowHiddenY

    return (
            <div>
                {
                    items.filter(item => !(exclude || []).includes(item.key)).map((item, i) => {
                        if (!(item.key === "page" || item.key === "search" || item.key === "sort" || item.key === "category")) {
                            return null;
                        }
                        item = {...item, ...itemsDict[item.value] || {}};
                        if (!item.value) {
                            return returnNull ? null : <div key={i} className="u-height32"></div>;
                        }
                        let label = item.value;
                        if (item.key === "search") {
                            label = "Search: " + label;

                        } else if (item.key === "category") {
                            label = "Category";
                        } else if (item.key === "page") {
                            if (label.toString() === "1") {
                                return returnNull ? null : <div key={i} className="u-height32"></div>;
                            }
                            label = "Page: " + label;
                        } else if (item.key === "sort") {
                            return null;
                        }
                        return (
                            <Chip
                                    key={i}
                                    clickable={false}
                                    className={"u-marginRight10 u-borderRadius50 u-height35 u-borderColorSecondary u-border1 u-backgroundTransparent u-textColorSecondary"}
                                    label={label}
                                    onDelete={() => onDelete(item.key)}
                                  />
                        );
                    })
                }
            </div>
    );
}
